let AWS = require("aws-sdk");

// initial state
const state = () => ({
    forms: [],
    form: {},
    styles: {}
});

const getters = {
    cssJsonToS3: state => data => {
        const spacesEndpoint = new AWS.Endpoint(`${process.env.    MIX_DO_SPACES_URL}`);
        let s3 = new AWS.S3({
            endpoint: spacesEndpoint,
            accessKeyId: process.env.MIX_DO_SPACES_ACCESS_KEY_ID,
            secretAccessKey: process.env.MIX_DO_SPACES_ACCESS_KEY
        });

        const formId = data.formId;
        const font = data.font;
        const fontFamily = data.font.family.replace(/\s+/g, "+");
        const bgColor = data.bgColor;
        const headerColor = data.headerColor;
        const labelColor = data.labelColor;
        const primaryColor = data.primaryColor;
        const secondaryColor = data.secondaryColor;
        const disclaimerColor = data.disclaimerColor;
        const colorScheme = data.colorScheme;
        const borderSize = data.borderSize;
        const borderRadius = data.borderRadius;
        const title1 = data.title1;
        const logoUrl = data.logoUrl;
        const showLogo = data.showLogo;
        const logo = data.logo;
        const logoType = data.logoType;
        const showLoanType = data.showLoanType;
        const loanTypeSelected = data.loanTypeSelected;
        const btnColor = data.btnColor;
        const btnBorderColor = data.btnBorderColor;
        const btnTxtColor = data.btnTxtColor;
        const btnRadius = data.btnRadius;
        const btnSize = data.btnSize;
        const btnTxt = data.btnTxt;
        const singleLender = data.singleLender;
        const affid = data.affid;
        const api_key = data.api_key;
        const campaign = data.campaign;

        // Upload the logo only for create, at least for now
        if (logo) {
            const logoData = {
                Bucket:
                    process.env.MIX_DO_SPACES_BUCKET + "/templates/" + formId,
                Key: formId + "." + logoType,
                Body: logo,
                ACL: "public-read",
                CacheControl: "no-cache"
            };
            s3.upload(logoData)
                .promise()
                .then(function(data) {
                    // return "success";
                })
                .catch(function(err) {
                    console.log("no");
                });
        }

        // Upload the css
        const cssBody = `@import url('https://fonts.googleapis.com/css2?family=${fontFamily}&display=swap');:root{--font:'${font.family +
            "', " +
            font.category};--header-color:${headerColor};--label-color:${labelColor};--disclaimer-color:${disclaimerColor};--primary-color:${primaryColor};--secondary-color:${secondaryColor};--bg-color:${bgColor};--border-size:${borderSize};--border-radius:${borderRadius};--btn-color:${btnColor};--btn-border-color:${btnBorderColor};--btn-radius:${btnRadius};--btn-txt-color:${btnTxtColor};--btn-size:${btnSize}}`;

        const cssData = {
            Bucket: process.env.MIX_DO_SPACES_BUCKET + "/templates/" + formId,
            Key: formId + ".css",
            Body: cssBody,
            ContentType: "text/css",
            ACL: "public-read"
        };

        s3.putObject(cssData)
            .promise()
            .then(function(data) {
                // return "success";
            })
            .catch(function(err) {
                // return "error";
            });

        // Upload Json
        const jsonBody = {
            formId: formId,
            font: font,
            bgColor: bgColor,
            headerColor: headerColor,
            labelColor: labelColor,
            primaryColor: primaryColor,
            secondaryColor: secondaryColor,
            disclaimerColor: disclaimerColor,
            colorScheme: colorScheme,
            borderSize: borderSize,
            borderRadius: borderRadius,
            showLogo: showLogo,
            logoUrl: logoUrl,
            title1: title1,
            showLoanType: showLoanType,
            loanTypeSelected: loanTypeSelected,
            btnColor: btnColor,
            btnBorderColor: btnBorderColor,
            btnTxtColor: btnTxtColor,
            btnRadius: btnRadius,
            btnSize: btnSize,
            btnTxt: btnTxt,
            singleLender: singleLender,
            affid: affid,
            api_key: api_key,
            campaign: campaign
        };

        const jsonData = {
            Bucket: process.env.MIX_DO_SPACES_BUCKET + "/templates/" + formId,
            Key: formId + ".json",
            Body: JSON.stringify(jsonBody),
            ContentType: "application/json",
            ACL: "public-read"
        };

        return s3
            .putObject(jsonData)
            .promise()
            .then(function(data) {
                return "success";
            })
            .catch(function(err) {
                // return "error";
            });
    },

    copyForm: state => data => {
        const spacesEndpoint = new AWS.Endpoint(`${process.env.MIX_DO_SPACES_URL}`);
        let s3 = new AWS.S3({
            endpoint: spacesEndpoint,
            accessKeyId: process.env.MIX_DO_SPACES_ACCESS_KEY_ID,
            secretAccessKey: process.env.MIX_DO_SPACES_ACCESS_KEY
        });

        const Bucket = process.env.MIX_DO_SPACES_BUCKET;
        const Folder = "templates/" + data.old_form_id + "/";

        const listParams = {
            Bucket: Bucket,
            Delimiter: "/",
            Prefix: Folder
        };

        // First we get the list of bojects to be copied
        const listObjects = s3
            .listObjects(listParams)
            .promise()
            .then(function(dataObjects) {
                return dataObjects;
            });

        return listObjects
            .then(res => {
                const copyPromisesArr = [];

                res.Contents.forEach(function(content) {
                    let fileExtention = content.Key.split(".")[1];
                    let copyParams = {
                        Bucket:
                            process.env.MIX_DO_SPACES_BUCKET +
                            "/templates/" +
                            data.new_form.form_id,
                        CopySource:
                            process.env.MIX_DO_SPACES_BUCKET +
                            "/" +
                            content.Key,
                        Key: data.new_form.form_id + "." + fileExtention,
                        ACL: "public-read"
                    };

                    //Copy to the new form_id folder
                    const promise = s3.copyObject(copyParams)
                    .promise();

                    copyPromisesArr.push(promise);
                });

                return Promise.all(copyPromisesArr);
            })
            .then(() => {
                ///// after copy, modify the json file. ////
                let getParams = {
                    Bucket:
                        process.env.MIX_DO_SPACES_BUCKET +
                        "/templates/" +
                        data.new_form.form_id,
                    Key: data.new_form.form_id + ".json"
                };

                // Here we get the object to be modified
                const getObject = s3
                    .getObject(getParams)
                    .promise()
                    .then(function(dataObject) {
                        return dataObject;
                    });

                return getObject.then(dataObject => {
                    let jsonContent = JSON.parse(dataObject.Body);
                    //Replace the formId
                    jsonContent.formId = data.new_form.form_id;
                    //Replace the logoUrl path to use the new form_id
                    let newLogoUrl = jsonContent.logoUrl;
                    jsonContent.logoUrl = newLogoUrl.replaceAll(
                        data.old_form_id,
                        data.new_form.form_id
                    );

                    let putParams = {
                        Body: JSON.stringify(jsonContent),
                        Bucket:
                            process.env.MIX_DO_SPACES_BUCKET +
                            "/templates/" +
                            data.new_form.form_id,
                        Key: data.new_form.form_id + ".json",
                        ContentType: "application/json",
                        ACL: "public-read"
                    };

                    // Here we pace back the json file modified
                    const putObject = s3
                        .putObject(putParams)
                        .promise()
                        .then(() => {
                            return "success";
                        });

                    return putObject;
                });
            });
    }
};

const actions = {
    getForms(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        return new Promise((resolve, reject) => {
            axios
                .get("/forms")
                .then(response => {
                    context.commit("getForms", response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    },

    createForm(context, data) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;

        return new Promise((resolve, reject) => {
            axios
                .post("/forms", {
                    type: data.type,
                    name: data.name
                })
                .then(response => {
                    if (response.status == 200) {
                        const templateData = {
                            formId: response.data.form_id,
                            font: {
                                category: "sans-serif",
                                family: "Lato"
                            },
                            bgColor: "#ffffff",
                            headerColor: data.headerColor,
                            labelColor: data.labelColor,
                            primaryColor: data.primaryColor,
                            secondaryColor: data.secondaryColor,
                            disclaimerColor: "#000000",
                            colorScheme: data.colorScheme,
                            borderRadius: "0px",
                            borderSize: "1px",
                            showLogo: data.logo != "" ? true : false,
                            logo: data.logo,
                            logoUrl:
                                data.logo != ""
                                    ? `https://${process.env.MIX_DO_SPACES_BUCKET}.${process.env.MIX_DO_SPACES_URL}/templates/${response.data.form_id}/${response.data.form_id}.${data.logoType}`
                                    : "",
                            logoType: data.logoType,
                            title1: "Get approved for a Personal Loan today!",
                            showLoanType: true,
                            loanTypeSelected: [
                                {
                                    type: "Debt Consolidation",
                                    icon: "debt",
                                    selected: true
                                },
                                {
                                    type: "Pay off Credit Cards",
                                    icon: "creditCard",
                                    selected: true
                                },
                                {
                                    type: "Home Improvement",
                                    icon: "home",
                                    selected: true
                                },
                                {
                                    type: "Purchase an Item",
                                    icon: "purchase",
                                    selected: true
                                },
                                {
                                    type: "Grow Your Business",
                                    icon: "business",
                                    selected: true
                                },
                                {
                                    type: "Cover Medical Expenses",
                                    icon: "medical",
                                    selected: true
                                },
                                {
                                    type: "Pay for a Wedding",
                                    icon: "wedding",
                                    selected: true
                                },
                                {
                                    type: "Buy a Car",
                                    icon: "car",
                                    selected: true
                                },
                                {
                                    type: "Take a Vacation",
                                    icon: "vacation",
                                    selected: true
                                },
                                {
                                    type: "Bills and Expenses",
                                    icon: "bills",
                                    selected: true
                                },
                                {
                                    type: "Rebuild Your Credit",
                                    icon: "rebuild",
                                    selected: true
                                },
                                {
                                    type: "Other",
                                    icon: "other",
                                    selected: true
                                }
                            ],
                            // Button Options
                            btnColor: data.primaryColor,
                            btnBorderColor: data.secondaryColor,
                            btnTxtColor: "#FFFFFF",
                            btnRadius: "0px",
                            btnSize: "50px",
                            btnTxt: "Apply Now",
                            // Single Lender options
                            singleLender: {
                                is_active: false,
                                name: "",
                                id: "",
                                logo: ""
                            },
                            campaign: {
                                ext_a: "",
                                ext_b: "",
                                ext_c: "",
                                ext_gid: "",
                                ext_pid: "",
                                utm_source: "",
                                utm_content: "",
                                utm_medium: "",
                                utm_campaign: "",
                                utm_term: ""
                            },
                            affid: response.data.affid,
                            api_key: response.data.api_key
                        };

                        const uploaded = context.getters
                            .cssJsonToS3(templateData)
                            .then(res => {
                                if (res == "success") {
                                    return response.data.id;
                                }
                            });
                        uploaded.then(res => {
                            resolve(res);
                        });
                    } else {
                        console.log(response);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        });
    },

    deleteForm(context, id) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        axios
            .delete("/forms/" + id)
            .then(response => {
                context.commit("deleteForm", id);
            })
            .catch(error => {
                console.log(error);
            });
    },

    getForm(context, id) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        axios
            .get("/forms/" + id)
            .then(response => {
                context.commit("getForm", response.data);
            })
            .catch(error => {
                console.log(error);
                window.location.href = "/forms";
            });
    },

    updateForm(context, form) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        axios
            .patch("/forms/" + form.id, {
                id: form.id,
                user_id: form.user_id,
                is_active: form.is_active,
                name: form.name
            })
            .then(response => {
                context.commit("updateForm", response.data);
            })
            .catch(error => {
                console.log(error);
            });
    },

    duplicateForm: async (context, id) => {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        return new Promise((resolve, reject) => {
            axios
                .post("/duplicate-form/", {
                    id: id
                })
                .then(response => {
                    // Copy assets on DO
                    context.getters.copyForm(response.data).then(res => {
                        if (res == "success") {
                            // Update the forms to display the new template duplicated
                            context.commit(
                                "updateForms",
                                response.data.new_form
                            );
                            resolve(res);
                        } else {
                            //If something whent wrong while copign we delete the template created
                            context.dispatch(
                                "deleteForm",
                                response.data.new_form.id
                            );
                            resolve(res);
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        });
    },

    // Pull json file with main styles from S3
    getStyle(context, id) {
        return fetch(
            `https://${process.env.MIX_DO_SPACES_BUCKET}.${process.env.MIX_DO_SPACES_URL}/templates/${id}/${id}.json`
        )
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();
            })
            .then(response => {
                context.commit("getStyle", response);
            })
            .catch(error => {
                console.log("error", error);
            });
    },
    // Update css and json styles and uplod to S3
    updateStyle(context, styles) {
        return context.getters.cssJsonToS3(styles);
    }
};

const mutations = {
    getForms(state, forms) {
        state.forms = forms;
    },
    deleteForm(state, id) {
        const index = state.forms.findIndex(item => item.id == id);
        state.forms.splice(index, 1);
    },
    getForm(state, form) {
        state.form = form;
    },
    updateForm(state, form) {
        state.form = form;
    },
    getStyle(state, styles) {
        // In case the template don't have campaign we add
        if (!styles.campaign) {
            styles.campaign = {
                ext_a: "",
                ext_b: "",
                ext_c: "",
                ext_gid: "",
                ext_pid: "",
                utm_source: "",
                utm_content: "",
                utm_medium: "",
                utm_campaign: "",
                utm_term: ""
            };
        }
        state.styles = styles;
    },
    updateForms(state, form) {
        state.forms = [...state.forms, form];
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
